import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

const fetchErrorData = async (language = "en/") => {
  const url = `${backendURL.concat(language)}api-v1/GetNodeByURL/error-page/$`;
  const response = await basicFetch(url);
  if (response.data) {
    return formatErrorData(response.data);
  }
};

const formatErrorData = (data) => {
  if (
    data[0] &&
    data[0].content_sections &&
    data[0].content_sections.data_struct_items
  ) {
    return {
      fourOhFourData: formatFourOhFourData(
        data[0].content_sections.data_struct_items,
        data?.[0]?.metatags
      ),
      fiveHundredData: formatFiveHundredData(
        data[0].content_sections.data_struct_items,
        data?.[0]?.metatags
      ),
    };
  }
};

const formatFourOhFourData = (data, metadata) => {
  const returnObj = {
    header:
      data.find((point) => {
        return point.attribute_name === "404 header text";
      }).attribute_value || null,
    subheader:
      data.find((point) => {
        return point.attribute_name === "404 subheader text";
      }).attribute_value || null,
    metatags: metadata,
  };

  const imageObj = data.find((point) => {
    return point.attribute_name === "error image";
  });

  if (imageObj) {
    const image = {
      url: imageObj.attribute_value.source,
      altText: "error image",
    };
    returnObj.image = image;
  }

  const ctaOneObj = data.find((point) => {
    return point.attribute_name === "home button text";
  });

  const ctas = {};

  if (ctaOneObj) {
    const ctaOne = {
      url: "/",
      text: ctaOneObj.attribute_value,
    };
    ctas.ctaOne = ctaOne;
  }

  const ctaTwoObj = data.find((point) => {
    return point.attribute_name === "go back button text";
  });

  if (ctaTwoObj) {
    const ctaTwo = {
      url: null,
      text: ctaTwoObj.attribute_value,
    };
    ctas.ctaTwo = ctaTwo;
  }
  returnObj.ctas = ctas;

  return returnObj;
};

const formatFiveHundredData = (data, metadata) => {
  const returnObj = {
    header:
      data.find((point) => {
        return point.attribute_name === "500 header text";
      }).attribute_value || null,
    subheader:
      data.find((point) => {
        return point.attribute_name === "500 subheader text";
      }).attribute_value || null,
    metatags: metadata,
  };

  const imageObj = data.find((point) => {
    return point.attribute_name === "error image";
  });

  if (imageObj) {
    const image = {
      url: imageObj.attribute_value.source,
      altText: "error image",
    };
    returnObj.image = image;
  }

  const ctaOneObj = data.find((point) => {
    return point.attribute_name === "home button text";
  });

  const ctas = {};

  if (ctaOneObj) {
    const ctaOne = {
      url: "/",
      text: ctaOneObj.attribute_value,
    };
    ctas.ctaOne = ctaOne;
  }

  const ctaTwoObj = data.find((point) => {
    return point.attribute_name === "go back button text";
  });

  if (ctaTwoObj) {
    const ctaTwo = {
      url: null,
      text: ctaTwoObj.attribute_value,
    };
    ctas.ctaTwo = ctaTwo;
  }
  returnObj.ctas = ctas;

  return returnObj;
};

export default fetchErrorData;
