import {
  handleGetItem,
  handleSetItem,
} from "../../utils/localStorage/localStorageHandler";

export const checkForSavedLanguage = (languages, override) => {
  // if the override is active, default to 'en'
  if (override) {
    setLanguageToLocalStorage("en");
    return "en";
  }

  const storedLanguage = handleGetItem("cec-language");
  if (storedLanguage) {
    return storedLanguage;
  } else {
    return checkForBrowserLanguage(languages);
  }
};

const checkForBrowserLanguage = (languages) => {
  const language = window.navigator.userLanguage || window.navigator.language;
  const languageCode = language.substring(0, 2);

  if (
    // check the languages array for the browser's lanugageCode and return true if it's present
    languages.reduce((bool, lang) => {
      if (languageCode === lang.id) {
        bool = true;
      }
      return bool;
    }, false)
  ) {
    setLanguageToLocalStorage(languageCode);
    return languageCode;
  } else {
    return null;
  }
};

const setLanguageToLocalStorage = (language) => {
  handleSetItem("cec-language", language);
};

export const checkForSavedRegion = (location, regions = []) => {
  const userLocation = location || "US";
  const storedRegion = handleGetItem("cec-region", undefined);
  if (storedRegion) {
    return storedRegion;
  } else {
    return checkBrowserForRegion(userLocation, regions);
  }
};

const checkBrowserForRegion = (userLocation = "US", regions = []) => {
  const formattedRegionData = regions.map((region) => {
    // The region/country data is a single string of country codes separated by commas. This turns it into a single array to make it more searchable. Probably not necessary?
    const countries = region.machine.split(",");
    return {
      countries: countries.map((country) =>
        country.toLowerCase ? country.toLowerCase() : null
      ),
      name: region.name,
    };
  });

  const checkedLocation =
    userLocation && userLocation.toLowerCase
      ? userLocation.toLowerCase()
      : null;
  const region = formattedRegionData.find((region) => {
    return region.countries.includes(checkedLocation);
  });

  if (region && checkedLocation) {
    setRegionToLocalStorage(region.name);
    return region.name;
  } else {
    return "North America";
  }
};

const setRegionToLocalStorage = (region) => {
  handleSetItem("cec-region", region);
};
