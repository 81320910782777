import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Hero5 } from "luna-one";
import {
  updateInnerPageNav,
  updateMetadata,
  updateStatus,
} from "../../actions";
import PropTypes from "prop-types";

const ErrorPage = (props) => {
  const content = props.type === '404' ? props.fourOhFourData : props.fiveHundredData;

  useEffect(() => {
    props.setInnerPageNav({
      pageTitle: content.header,
      links: [],
    });
  
    props.setMetaData(content.metatags);
  }, [props, content.header, content.metatags]);


  if (props.mainError) {
    return (
      <div style={{ marginTop: "150px" }}>
        <Hero5 content={props.content} motion type="hero_5" />
      </div>
    );
  }
  // Note: This has a side effect if you start on a page without a langcode, ex. /test.
  // You get redirected to /en/test which is a 404.
  // Now, the last item in history is /en/test and you get stuck in a 404 loop. This is expected though.

  if (content.ctas && content.ctas.ctaTwo && props.hasOwnProperty('history')) {
    content.ctas.ctaTwo.onClick = props.history.goBack;
  }

  return (
    <div style={{ marginTop: "150px" }}>
      <Hero5 content={content} motion type="hero_5" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fourOhFourData: state.fourOhFourData,
    fiveHundredData: state.fiveHundredData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInnerPageNav: (data) => dispatch(updateInnerPageNav(data)),
  setMetaData: (data) => dispatch(updateMetadata(data)),
  setStatus: (data) => dispatch(updateStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);

ErrorPage.propTypes = {
  type: PropTypes.oneOf(['404', '500']).isRequired,
  fourOhFourData: PropTypes.object,
  fiveHundredData: PropTypes.object,
  setInnerPageNav: PropTypes.func,
};
