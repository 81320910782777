import React, { Component, lazy, Suspense  } from "react";
import "./App.scss";

import { withRouter } from "react-router-dom";

import fetchLanguages from "../../utils/async/fetchLanguages";
import fetchRegions from "../../utils/async/fetchRegions";
import fetchMenus from "../../utils/async/fetchMenus";
import fetchApplicationData from "../../utils/async/fetchApplicationData";
import fetchFooterData from "../../utils/async/fetchFooterData";
import fetchDrupalRedirects from "../../utils/async/fetchDrupalRedirects";
import fetchRoutesData from "../../utils/async/fetchRoutesData";
import formatRegionLanguageSelectorData from "../../utils/methods/formatRegionLanguageSelectorData";
import parseHtmlEntities from "../../utils/helpers/parseHTMLEntities";
import mockNavBarData from "../../utils/mock-data/mockNav";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import fetchIndustries from "../../utils/async/fetchIndustries";
import {
  setIndustries,
  updateLanguage,
  setLanguageOptions,
  updateRegion,
  setFourOhFourData,
  setFiveHundredData,
  setGeneralLabels,
  setReferralURL,
} from "../../actions";
import { checkForSavedLanguage } from "./AppMethods";

import fetchErrorData from "../../utils/async/fetchErrorData";
import fetchBrowserBanner from "../../utils/async/fetchBrowserBanner";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import hardCoded500 from "../../utils/helpers/hardCoded500";
import * as Sentry from "@sentry/browser";
import {
  handleGetItem,
  handleSetItem,
  handleRemoveItem,
} from "../../utils/localStorage/localStorageHandler";

const Loading = lazy(() => import("../../components/Loading/Loading.js"));
const LazyNavBar = lazy(() => import("../../components/LazyComponents/LazyNavBar.js"));
const LazyFooter = lazy(() => import("../../components/LazyComponents/LazyFooter.js"));
const LazyScrollToTop = lazy(() => import("../../components/LazyComponents/LazyScrollToTop.js"));
const LazyRouting = lazy(() => import("../Routing/Routing"));
const LazyBrowserSupportBanner = lazy(() => import("../../components/LazyComponents/LazyBrowserSupportBanner.js"));

const LanguageCodes = ['en', 'es', 'de', 'fr', 'sv', 'da', 'nb', 'pt-br'];

class App extends Component {
  constructor() {
    super();
    this.state = {
      mainStatus: "loading",
      navOptions: null,
      divisionName: null,
      searchPlaceholder: null,
      rightLinks: null,
      navCTA: null,
      footerData: null,
      regions: null,
      languages: null,
      drupalRedirects: [],
      routesData: [],
      regionLanguageDeployed: false,
      lastScrollPosition: 0,
      navBarPositonChanged: false,
    };
  }

  componentDidMount() {
    this.clearLocalStorageOnPageLoad();
    this.kickOff();
    // will only call this method IF browser is IE
    if (this.checkForIE()) {
      window.addEventListener("scroll", this.checkForScroll);
    }

    // Listen to back button click.
    this.backListener = this.props.history.listen((location) => this.checkForBackButton(location));
  }

  componentWillUnmount() {
    // will only call this method IF browser is IE
    if (this.checkForIE()) {
      window.removeEventListener("scroll", this.checkForScroll);
    }
    this.backListener();
  }

  //getting the referrer using location
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // set this into the Redux store
      this.props.setReferralURL(prevProps.location.pathname);
    }
  }

  clearLocalStorageOnPageLoad = () => {
    // evalutes the versioned local storage data for the site
    const version = handleGetItem("cec-version");
    const currentVersion = process.env.REACT_APP_VERSION;

    if (version && version !== currentVersion) {
      handleRemoveItem("cec-language");
      handleRemoveItem("cec-region");

      this.setVersion(currentVersion);
    } else if (!version) {
      this.setVersion(currentVersion);
    }
  };

  setVersion = (version) => {
    handleSetItem("cec-version", version);
  };

  languageOverride = () => {
    // This boolean is used to indicate that the language/region selector is disabled and all language references should resolve to 'en'
    return false;
  };

  checkForIE = () => {
    const userAgent = navigator.userAgent;

    // MSIE used to detect old browsers and Trident used to newer ones (checks for IE11)
    const is_ie =
      userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;

    return is_ie;
  };

  setPrerender = (bool) => {
    window.prerenderReady = bool;
  };

  checkForScroll = (e) => {
    const position = window.scrollY;
    if (position > this.state.lastScrollPosition + 100) {
      this.setState({
        navBarPositonChanged: true,
        lastScrollPosition: position,
        isOpen: null,
      });
    } else if (position < this.state.lastScrollPosition) {
      this.setState({
        navBarPositonChanged: false,
        lastScrollPosition: position,
      });
    }
  };

  kickOff = () => {
    this.setPrerender(false);
    this.initialLanguageAndRegionSet();
    this.getSiteData();
  };

  toggleRegionLanguageDeployed = () => {
    if (this.languageOverride()) {
      // If override is active, never open the region/language switcher
      this.setState({
        regionLanguageDeployed: false,
      });
    } else {
      // Toggles the Region/Language switcher modal
      this.setState({
        regionLanguageDeployed: !this.state.regionLanguageDeployed,
      });
    }
  };

  closeModal = () => {
    // Closes the Region/Language switcher modal
    this.setState({ regionLanguageDeployed: false });
  };

  getLanguage = () => {
    // Returns the language from redux with '/' for use in generating language-specific endpoints
    const getLangUrl = window.location.pathname;
    let lang = getLangUrl.split('/');
    // If path is like /products/test, langcode is props.
    // Otherwise it will be incorrectly set to 'products'
    if (!LanguageCodes.includes(lang[1])) {
      lang = this.props.language;
    } else {
      // Use the langcode from the URL.
      lang = lang[1];
    }
    return `${lang || 'en'}/`;
  };

  getSiteData = async (newLanguageCode) => {
    // newLanguageCode is passed only if getSiteData is being called as a result of a language change
    // Needs to be passed due to asynchronous state setting, can't just rely on what's in state as it won't be updated yet
    this.setMainStatusLoading();
    const lang = this.getLanguage();
    try {
      // fetch general application data from Drupal
      const {
        divisionName,
        searchPlaceholder,
        rightLinks,
        navCTA,
        allLabel,
        industryLabel,
        categoryLabel,
        supportLabel,
        supportPageSubHeader,
        allProductsLabel,
        learnMoreLabel,
      } = fetchApplicationData(lang);

      this.props.setGeneralLabels({
        allLabel,
        industryLabel,
        categoryLabel,
        supportLabel,
        supportPageSubHeader,
        learnMoreLabel,
      });

      if (this.checkForIE()) {
        const browserMessage = await fetchBrowserBanner(this.getLanguage());
        this.setState({ browserMessage });
      }

      // Fetch everything all at once.
      const requests = [
        fetchFooterData(lang),
        fetchMenus(lang, allProductsLabel),
        fetchIndustries(lang),
        fetchDrupalRedirects(lang),
        fetchRoutesData(lang),
        fetchErrorData(lang)
      ];

      // Promises always resolve in order.
      // If one call fails, everything fails.
      const responses = await Promise.all(requests);
      const [footerData, navOptions, industries, drupalRedirects, routesData, errorData] = responses;
      const { fourOhFourData, fiveHundredData } = errorData;


      this.props.setIndustries(industries);
      this.props.setFourOhFour(fourOhFourData);
      this.props.setFiveHundred(fiveHundredData);

      // 'mainStatus' refers to the main loading process for the application and is only stored in App.js, as opposed to the general 'status' which is stored in Redux and is used for conditionally rendering page and footer data
      this.setState({
        navOptions,
        divisionName,
        searchPlaceholder,
        rightLinks,
        navCTA,
        footerData,
        routesData,
        drupalRedirects
      });

      // All data was fetched and processed.
      this.setMainStatusSuccess();
    } catch (e) {
      console.log(e);
      this.setMainStatusError();
      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        Sentry.captureException(e);
      }
    }
  };

  initialLanguageAndRegionSet = async () => {
    try {
      let language = this.getLanguage();
      // Set language to local storage. Remove trailing /
      handleSetItem("cec-language", language.substring(0, language.length - 1));

      // fetch the language options data from Drupal
      const languages = fetchLanguages(language);
      // fetch the region options data from Drupal.
      const regions = fetchRegions(language);
      // Query localStorage, then the browser language preference
      // const userLocation = await fetchUserLocation(this.getLanguage());

      // Check the browser/local storage for language/region preferences
      // const region = checkForSavedRegion(userLocation, regions);
      language = checkForSavedLanguage(languages, this.languageOverride());

      this.props.setLanguages(languages);
      this.setLanguage(language);
      this.setState({ regions });
      this.forwardUserForWrongLanguage(languages);
    } catch (e) {
      console.log(e);
      this.setMainStatusError();
      Sentry.captureException(e);
    }
  };

  setLanguageAndRegion = (data = {}) => {
    let languageChange = false;

    // Used if the language is changed
    let newLanguageCode;

    // cloning the old language
    const oldLanguageCode = JSON.parse(JSON.stringify(this.props.language));

    // Should be in the format "EN: English".
    if (data.language) {
      let langAr = data.language.split(':');
      newLanguageCode =
        langAr[0] &&
        langAr[0].toLowerCase
          ? langAr[0].toLowerCase()
          : null;
      // Determine if the language has changed
      if (newLanguageCode !== this.props.language) {
        this.setLanguage(newLanguageCode);
        languageChange = true;
      }
    }

    if (data.region) {
      // Determine if the region has changed
      if (data.region !== this.props.region) {
        this.props.setRegion(data.region);
      }
    }

    // Only make a call for new site data if the language has changed
    if (languageChange) {
      this.setMainStatusLoading();
      this.forwardUserForNewLanguage(oldLanguageCode, newLanguageCode);
      this.getSiteData(newLanguageCode);
    }

    this.closeModal();
  };

  setLanguage = (language) => {
    if (this.languageOverride()) {
      this.props.setLanguage("en");
    } else {
      this.props.setLanguage(language);
    }
  };

  forwardUserForNewLanguage = (oldLanguageCode, newLanguageCode) => {
    // Fowards the user to the new address when the language changes
    const { pathname } = this.props.location;

    if (pathname === `/${oldLanguageCode}`) {
      // Only used if on the homepage
      const newPathname = pathname.replace(
        `/${oldLanguageCode}`,
        `/${newLanguageCode}`
      );

      // push the user to the new homepage with the new language prefix
      this.props.history.push(newPathname);
    } else {
      // Used if on any other page
      const newPathname = pathname.replace(
        `/${oldLanguageCode}/`,
        `/${newLanguageCode}/`
      );

      // push the user to the new page (not home) with the new language prefix
      this.props.history.push(newPathname);
    }
  };

  /**
   * Checks if the url contains the wrong langcode. Basically just calls
   * checkForWrongLanguage with guard statements before it.
   */
  forwardUserForWrongLanguage = (languages) => {
    const { pathname } = this.props.location;
    const splitPath = pathname.split("/");
    // Check for normal match.
    if (splitPath[1] === this.props.language) {
      if (pathname === `/${this.props.language}`) {
        // Check for homepage match.
        return;
      }
      // Check for non-homepage match. Add 2 to account for the leading and trailing /.
      if (pathname.substring(0, this.props.language.length + 2) === `/${this.props.language}/`) {
        return;
      }
    }
    if (this.checkForWrongLanguage(pathname, languages).result) {
      const wrongLanguage = this.checkForWrongLanguage(pathname, languages);
      const wrongLanguageID = wrongLanguage && wrongLanguage.id ? wrongLanguage.id : null;
      this.props.setLanguage(wrongLanguageID);
    }
  };

  checkForWrongLanguage = (pathname, languages = []) => {
    // Determine if the the language prefix in the address bar does not match the preferred/browser language in redux
    // Note: If there's a redirect like /da-test, it will incorrectly set the language to DA
    // regardless of what the user has selected.
    return languages.reduce(
      (accu, language = {}) => {
        if (
          // This looks weird, it's just null checking
          `/${language.id}` ===
          pathname.substring(
            0,
            language && language.id && language.id.length
              ? language.id.length + 1
              : 0
          )
        ) {
          if (
            `/${language.id}` === pathname ||
            `/${language.id}` === pathname.substring(0, language.id.length + 1)
          ) {
            accu.result = true;
            accu.id = language.id;
          }
        }
        return accu;
      },
      { result: false }
    );
  };

  checkForBackButton = (location) => {
    // Browser back button does not reload data if language switched.
    const newLanguage = location.pathname.substring(1,3);
    // Force reload if language is different.
    if (newLanguage !== this.props.language) {
      this.kickOff();
    }
  };

  getCurrentLanguage = () => {
    // Determine the current language and region from state and format the strings appropriately for the Navbar
    const region = this.props.region || "North America";

    // Create the language string for the navbar
    const language = `${
      this.props.language ? this.props.language.toUpperCase() : "EN"
    }: ${this.setFullLanguageName()}`;

    return { region, language };
  };

  setFullLanguageName = () => {
    // Grab the language code from Redux
    const language = this.props.language || "en";

    // Return the full language object from Redux based on the current lanugage code
    const activeLanguageObject = this.props.languages.find((lang) => {
      return lang.id === language;
    });

    if (activeLanguageObject) {
      // Return just the full name if present
      return activeLanguageObject.name;
    } else {
      return "";
    }
  };

  searchSubmit = async (string) => {
    // handles search submission from the navbar. The <SearchResultsPage> takes the search parameters out of the route and uses them to query Drupal for results.
    // TODO App - Remove this return once Search is working, pls

    let formattedString = string.replace(" ", "%20");
    this.props.history.push(
      `/${this.props.language || "en"}/search/${formattedString}`
    );
  };

  // Main status refers to the application having all the data it needs to start making API calls to render pages
  // Means that all data for languages, regions, navbar, footer, misc. application data is in-place and language/region are set
  setMainStatusSuccess = () => {
    this.setState({ mainStatus: "success" });
  };

  setMainStatusLoading = () => {
    this.setState({ mainStatus: "loading" });
  };

  setMainStatusError = () => {
    this.setState({ mainStatus: "error" });
  };

  render() {
    const {
      // TODO - setup search submit functionality
      logo,
    } = mockNavBarData;

    const {
      mainStatus,
      navOptions,
      divisionName,
      searchPlaceholder,
      rightLinks,
      navCTA,
    } = this.state;

    const { metadata = {} } = this.props;
    return (
      <div
        className={`App ${this.languageOverride() &&
          "App--override-language-switcher"}`}
      >
        <Suspense fallback="Loading...">
          <LazyScrollToTop />
        </Suspense>
        <Helmet htmlAttributes={{ lang: this.props.language }}>

          {/* As CEC is currently a single-language site, the following link is unnecessary. If more languages are added, this can be restored + duplicated to indicate each language's homepage */}
          {/* <link
            rel="alternate"
            hrefLang={this.props.language ? this.props.language : "en"}
            href={`https://heavyindustry.trimble.com/${this.props.language ? this.props.language : "en"}`}
            data-react-helmet="true"
          /> */}

          {this.props.status === "error" && (
            <meta name="prerender-status-code" content="501" />
          )}

          {metadata.og_image && (
            <meta property="og:image" content={metadata.og_image} />
          )}
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={
              metadata.description ||
              "From the office to the field, Trimble Heavy Industry has a wide range of technology solutions to make you more profitable and enable rapid decision-making."
            }
          />
          <title>
            {metadata.title !== undefined ? parseHtmlEntities(metadata.title) : "Trimble Civil Engineering and Construction"}
          </title>
          {metadata?.canonical_url && (
            <link
              rel="canonical"
              href={`https://heavyindustry.trimble.com${metadata.canonical_url}`}
            />
          )}
          {
            // -BW-
            <meta
              name="google-site-verification"
              content="sYgUhtITy75Z3ywCuofFL45uGZtsopt3xCA50bZwDUo"
            />
          }
        </Helmet>
        {this.state.browserMessage && this.props.status === "success" && (
          <div
            className={
              this.state.navBarPositonChanged
                ? "browser-banner adjust-banner-position"
                : "browser-banner"
            }
          >
            <Suspense fallback="Loading...">
              <LazyBrowserSupportBanner content={this.state.browserMessage} />
            </Suspense>
          </div>
        )}
        {this.state.navOptions && (
          <Suspense fallback={`Loading...`}>
            <LazyNavBar
              // Note - When we transition to Luna Two, this can all go in a content object like with heroes/featurettes
              regionLanguageDeployed={this.state.regionLanguageDeployed}
              toggleRegionLanguageDeployed={this.toggleRegionLanguageDeployed}
              closeModal={this.closeModal}
              searchPlaceholder={searchPlaceholder}
              currentLanguage={this.getCurrentLanguage()}
              searchSubmit={this.searchSubmit}
              rightLinks={rightLinks}
              divisionName={divisionName}
              navOptions={navOptions}
              navCTA={
                this.props.innerPageNav && this.props.innerPageNav.override
                  ? { link: this.props.innerPageNav.override }
                  : navCTA
              }
              utilityNav={this.props.innerPageNav || {}}
              setLanguage={this.setLanguageAndRegion}
              language={this.props.language}
              languageSelectorData={formatRegionLanguageSelectorData(
                this.props.languages,
                this.state.regions,
                this.state.divisionName,
                this.props.region,
                this.props.language
              )}
              logo={logo}
              location={this.props.location}
              overrideLanguageSelector={this.languageOverride()}
            />
          </Suspense>
        )}
        <main className="page-main">
          <Suspense fallback={`Loading...`} >
            <LazyRouting
              getSiteData={this.getSiteData}
              mainStatus={this.state.mainStatus}
              setMainStatusError={this.setMainStatusError}
              setMainStatusLoading={this.setMainStatusLoading}
              drupalRedirects={this.state.drupalRedirects}
              routesData={this.state.routesData}
              toggleRegionLanguageDeployed={this.toggleRegionLanguageDeployed}
            />
          </Suspense>
          {mainStatus === "loading" && (
            <Suspense fallback="Loading">
              <Loading />
            </Suspense>
          )}
          {mainStatus === "error" && (
            <Suspense fallback="Loading...">
              <ErrorPage content={hardCoded500} mainError history={() => {}} />
            </Suspense>
          )}
        </main>
        {this.state.footerData && mainStatus === "success" && (
          <Suspense fallback={`Loading Footer...`} >
            <LazyFooter
              // Note - When we transition to Luna Two, this can all go in a content object like with heroes/featurettes
              trimbleText={this.state.footerData.trimbleText}
              trimbleLink={this.state.footerData.trimbleLink}
              siteText={this.state.footerData.siteText}
              siteLink={this.state.footerData.siteLink}
              linksOne={this.state.footerData.linksOne}
              linksTwo={this.state.footerData.linksTwo}
              logo={this.state.footerData.logo}
              socialLinks={this.state.footerData.socialLinks}
              legalStuff={this.state.footerData.legalStuff}
            />
          </Suspense>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languages: state.languages,
    language: state.language,
    region: state.region,
    metadata: state.metadata,
    innerPageNav: state.innerPageNav,
    status: state.status,
    generalLabels: state.generalLabelsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRegion: (region) => dispatch(updateRegion(region)),
    setLanguage: (language) => dispatch(updateLanguage(language)),
    setLanguages: (options) => dispatch(setLanguageOptions(options)),
    setIndustries: (industries) => dispatch(setIndustries(industries)),
    setFourOhFour: (data) => dispatch(setFourOhFourData(data)),
    setFiveHundred: (data) => dispatch(setFiveHundredData(data)),
    setGeneralLabels: (data) => dispatch(setGeneralLabels(data)),
    setReferralURL: (url) => dispatch(setReferralURL(url)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
