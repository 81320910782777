const fetchRegions = () => {
  // Hard code this response. The region_list taxonomy doesn't change.
  // Also, only North America is returned?
  return [
    {
      id: "76",
      name: "North America",
      machine: "AI,AG,BS,BB,BZ,BM,CA,CR,CU,DM,DO,SV,GD,GP,GT,HT,HN,JM,MQ,MX,MS,NI,PA,PR,BL,KN,LC,MF,PM,VC,SX,TT,TC,US,VG,VI",
      parents: ["0"],
      children: [],
      iso_code: "AI,AG,BS,BB,BZ,BM,CA,CR,CU,DM,DO,SV,GD,GP,GT,HT,HN,JM,MQ,MX,MS,NI,PA,PR,BL,KN,LC,MF,PM,VC,SX,TT,TC,US,VG,VI"
    }
  ];

  // const url = `${backendURL.concat(language)}api-v1/FetchTaxonomyByMachineName/region_list`;
  // const response = await basicFetch(url);

  // if (response.data) {
  //   return response.data;
  // } else {
  //   throw new Error();
  // }
};

export default fetchRegions;
