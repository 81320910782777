const fetchLanguages = () => {
  // Hard code this response. Languages do not change.
  return [
    {
        "id": "en",
        "name": "English",
        "default": true,
        "direction": "ltr"
    },
    {
        "id": "es",
        "name": "Spanish",
        "default": false,
        "direction": "ltr"
    },
    {
        "id": "de",
        "name": "German",
        "default": false,
        "direction": "ltr"
    },
    {
        "id": "fr",
        "name": "French",
        "default": false,
        "direction": "ltr"
    },
    {
        "id": "sv",
        "name": "Swedish",
        "default": false,
        "direction": "ltr"
    },
    {
        "id": "da",
        "name": "Danish",
        "default": false,
        "direction": "ltr"
    },
    {
        "id": "nb",
        "name": "Norwegian",
        "default": false,
        "direction": "ltr"
    },
    {
        "id": "pt-br",
        "name": "Portuguese, Brazil",
        "default": false,
        "direction": "ltr"
    }
  ];
  // const url = `${backendURL}en/api-v1/GetLanguages`;
  // const response = await basicFetch(url);
  // if (response.data) {
  //   return response.data;
  // } else {
  //   throw new Error();
  // }
};

export default fetchLanguages;
