// DO NOT remove the trailing slash on the url!

// if (process.env.REACT_APP_DOMAIN === "development") {
// 	backendURL = "https://dev-heavyindustry.trimble.com/";
// } else if (process.env.REACT_APP_DOMAIN === "staging") {
// 	backendURL = "https://stg-heavyindustry.trimble.com/";
// } else if (process.env.REACT_APP_DOMAIN === "production") {
// 	backendURL = "https://back-heavyindustry.trimble.com/";
// }

const backendURL = process.env.REACT_APP_BACKEND_URL + '/';

export default backendURL;
