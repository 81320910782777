// Replace any html char codes with symbol.
//  ex. parseHTMLEntities('&#62;') => '>'

export default function parseHtmlEntities(str) {
  return str.replace(/&#([0-9]{1,3});/gi, (match, numStr) => {
    // read num as normal number.
    const num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  });
}
