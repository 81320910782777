import formatApplicationData from "../methods/formatApplicationData";

const fetchApplicationData = () => {
  // Hard code this response.
  // Corresponsding Drupal page does not get updated. Title: "Site Application Data"
  const data = {
    "name": "Site Application Data",
    "id": "361",
    "type": "site_application_data",
    "revision_created": "1673975977",
    "luna_major_version": "1",
    "luna_version": "1.0.0",
    "country_settings": [],
    "division_name": "Civil Construction",
    "search_bar_placeholder": "search",
    "top_navbar_link": [
        {
            "url": "\/en\/contact",
            "text": "Contact"
        },
        {
            "url": "\/support",
            "text": "Support"
        }
    ],
    "utility_nav_link": [
        {
            "url": "\/en\/where-to-buy",
            "text": "Where to Buy"
        }
    ],
    "all_label": "All",
    "category_label": "Category",
    "industry_label": "Industry",
    "support_label": "Support",
    "support_page_subheader": "We understand the importance of minimizing downtime in your daily operations. Trimble offers a range of support options to keep you working.",
    "all_products_label": "All Products",
    "learn_more_label": "Learn More",
    "page_name": null,
    "nav_cta_override": {
      "toggle": null,
      "link": null
    },
    "regions": [],
    "metatags": {
      "canonical_url": "\/site-application-data",
      "title": "[node:field_page_name] | Trimble Civil Construction",
      "description": "[node:field_teaser_description]"
    },
    "content_sections": []
  }
  
  // const url = `${backendURL.concat(language)}api-v1/GetNodeByURL/site-application-data/$`;
  // const response = await basicFetch(url);
  return formatApplicationData(data);
};

export default fetchApplicationData;
