const formatRegionLanguageSelectorData = (
  sitewideLanguages,
  sitewideRegions,
  location,
  language
) => {
  if (!sitewideLanguages || !sitewideRegions) {
    return null;
  }

  const regions = sitewideRegions.map((region = {}) => {
    return {
      region: region.name,
      languages: sitewideLanguages.map((language = {}) => {
        const id = language.id || "";
        return `${id.toUpperCase()}: ${language.name}`;
      }),
    };
  });

  return {
    regions,
    labels: {
      currentRegion: "Current Region",
      cancel: "Cancel",
      save: "Save",
    },
    activeRegion: {
      region: location,
      language: language,
    },
  };
};

export default formatRegionLanguageSelectorData;
