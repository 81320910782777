import backendURL from "../helpers/backendURL";
import basicFetch from "./basicFetch";

const fetchIndustries = async (language = "en/") => {
  const url = `${backendURL.concat(language)}api-v1/FetchTaxonomyByMachineName/cec_industries`;
  const response = await basicFetch(url);

  return formatIndustries(response.data);
};

const formatIndustries = data => {
  return data.map(industry => {
    return industry.name;
  });
};

export default fetchIndustries;
