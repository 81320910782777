import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";
import formatFooterData from "../methods/formatFooterData";

const fetchFooterData = async (language = "en/") => {
  const url = `${backendURL.concat(language)}api-v1/FetchBlockByMachineName/luna_drupal_heavy_footer`;
  const { data } = await basicFetch(url);
  return formatFooterData(data);
};

export default fetchFooterData;
