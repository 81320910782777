import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import linkedin from "../../assets/images/linkedin.svg";
import instagram from "../../assets/images/instagram.svg";
import tiktok from "../../assets/images/tiktok.svg";

const formatFooterData = (footerData) => {
  const data = footerData || {};

  return {
    logo: formatLogo(data.logo),
    trimbleText: data.trimble_specific_text,
    trimbleLink: formatLink(data.trimble_specific_link),
    siteLink: formatLink(data.site_specific_link),
    siteText: data.site_specific_text,
    linksOne: data.link_list_1 || [],
    linksTwo: data.link_list_2 || [],
    socialLinks: formatSocialLinks(data.social),
    legalStuff: formatLegalStuff(data.trimble_bar),
  };
};

export const formatLogo = (data) => {
  if (data) {
    return {
      url: data,
      altText: "Trimble Logo",
    };
  } else {
    return null;
  }
};

export const formatLegalStuff = (legalStuffData) => {
  const data = legalStuffData || [];

  return data.map((point) => {
    const formattedPoint = { text: point.text };

    if (point.url !== "<nolink>") {
      formattedPoint.url = point.url;
    }

    return formattedPoint;
  });
};

export const formatSocialLinks = (socialLinkData) => {
  const data = socialLinkData || {};

  const keys = Object.keys(data);

  return keys.map((key) => {
    return {
      icon: {
        altText: `${key} logo`,
        url: socialIcons[key],
      },
      link: {
        url: data[key],
      },
    };
  });
};

const socialIcons = {
  facebook,
  twitter,
  linkedin,
  youtube,
  instagram,
  tiktok,
};

export const formatLink = (linkData) => {
  const data = linkData || {};

  if (data.url && data.text) {
    return {
      url: data.url,
      text: data.text,
    };
  } else {
    return null;
  }
};

export default formatFooterData;
