import basicFetch from "./basicFetch";
import backendURL from "../helpers/backendURL";

const fetchDrupalRedirects = async (lang = 'en') => {
 try {
  const res = await basicFetch(backendURL.concat(lang) + 'api-v1/Redirects');
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('Recieved non 200 status from fetchDrupalRedirects');
  }
 }
 catch(err) {
  console.error(err);
 }
}

export default fetchDrupalRedirects;