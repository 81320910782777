import "react-app-polyfill/ie11";
import "babel-polyfill";
import "isomorphic-fetch";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-P4Z726Z",
};

TagManager.initialize(tagManagerArgs);

const ignoredErrors = [
  "Network Error",
  "f is not a function",
  `SyntaxError: SyntaxError: DOM Exception 12`,
  `SyntaxError: DOM Exception 12`,
  "Request aborted",
];

const store = createStore(rootReducer, composeWithDevTools());
Sentry.init({
  dsn: "https://5a689f8569804214806d0d89f0485ca6@sentry.io/2714540",
  beforeSend(event, hint) {
    const error = hint?.originalException?.message;

    if (
      process.env.NODE_ENV === "development" ||
      ignoredErrors.includes(error)
    ) {
      return null;
    } else {
      return event;
    }
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
