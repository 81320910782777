export const formatApplicationData = (applicationData) => {
  const data = applicationData || {};
  // Formats data fetched during application load
  return {
    divisionName: data.division_name,
    searchPlaceholder: data.search_bar_placeholder,
    rightLinks: data.top_navbar_link || [],
    navCTA: formatNavCTA(data),
    allLabel: data.all_label,
    industryLabel: data.industry_label,
    categoryLabel: data.category_label,
    supportLabel: data.support_label,
    supportPageSubHeader: data.support_page_subheader,
    allProductsLabel: data.all_products_label,
    learnMoreLabel: data.learn_more_label,
  };
};

export const formatNavCTA = (navCTAData) => {
  const data = navCTAData || {};

  if (data.utility_nav_link && data.utility_nav_link[0]) {
    return { link: data.utility_nav_link[0] };
  } else {
    return {};
  }
};

export default formatApplicationData;
