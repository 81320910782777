import { combineReducers } from "redux";
import { statusReducer } from "./statusReducer";
import { languageReducer } from "./languageReducer";
import { innerPageNavReducer } from "./innerPageNavReducer";
import { metadataReducer } from "./metadataReducer";
import { offsetYReducer } from "./offsetYReducer";
import { regionReducer } from "./regionReducer";
import { languagesReducer } from "./languagesReducer";
import { industriesReducer } from "./industriesReducer";
import { fiveHundredReducer } from "./fiveHundredReducer";
import { fourOhFourReducer } from "./fourOhFourReducer";
import { generalLabelsReducer } from "./generalLabelsReducer";
import { referralURLReducer } from "./referralURLReducer";

const rootReducer = combineReducers({
  status: statusReducer,
  language: languageReducer,
  languages: languagesReducer,
  innerPageNav: innerPageNavReducer,
  metadata: metadataReducer,
  offsetY: offsetYReducer,
  region: regionReducer,
  industries: industriesReducer,
  fiveHundredData: fiveHundredReducer,
  fourOhFourData: fourOhFourReducer,
  generalLabelsData: generalLabelsReducer,
  referralURL: referralURLReducer,
});

export default rootReducer;
