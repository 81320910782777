const organizeMenus = (responseData, allProductsLabelData) => {
  const data = responseData || [];
  const allProductsLabel = allProductsLabelData || "All Products";

  // Format menu JSON for the NavBar
  const menusSetByType = setMenusByType(data) || [];
  const organizedMenus = menusSetByType.map((menu = {}) => {
    const organizedMenu = {
      text: menu.title,
      type: menu.type,
    };

    if (menu.type === "link") {
      organizedMenu.url = menu.url;
    }

    if (menu.type === "mini menu") {
      organizedMenu.links = organizeMiniMenu(menu);
    }

    if (menu.type === "mega menu") {
      // Format the MegaMenu content
      if (menu.url && menu.url !== "/") {
        // Set the left-side bottom link for the MegaMenu
        organizedMenu.bottomLink = {
          url: menu.url,
          text: allProductsLabel,
        };
      } else {
        menu.url = null;
      }
      organizedMenu.links = organizeMegaMenu(menu);
    }
    return organizedMenu;
  });

  return organizedMenus;
};

export const setMenusByType = (data) => {
  const menus = data
    ? data.map((point = {}) => {
        if (point.children) {
          // Check to see if the object's children array has nested children array and define as a mega/mini menu
          const megaMenu = checkforMegaMenu(point.children);
          if (megaMenu) {
            point.type = "mega menu";
          } else {
            point.type = "mini menu";
          }
        } else {
          // If the object has no children, define it as a link
          point.type = "link";
        }

        return point;
      })
    : [];
  return menus;
};

export const checkforMegaMenu = (data) => {
  const children = data || [];

  return children.reduce((result, child) => {
    // iterate over all the children and check for nested children arrays, returning true if any are found
    if (child && child.children) {
      result = true;
    }
    return result;
  }, false);
};

export const organizeMegaMenu = (menuData) => {
  const menu = menuData || {};
  // format the mega menu object to fit the NavBar
  return (menu.links = menu.children
    ? menu.children.map((child = {}) => {
        return {
          text: child.title,
          links: child.children
            ? child.children.map((grandchild) => {
                return {
                  url: grandchild.url,
                  text: grandchild.title,
                };
              })
            : [],
        };
      })
    : []);
};

export const organizeMiniMenu = (menu = {}) => {
  // format the mini menu object to fit the NavBar

  return menu && menu.children
    ? menu.children.map((child = {}) => {
        return {
          url: child.url,
          text: child.title,
        };
      })
    : [];
};

export default organizeMenus;
